import React from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button } from 'reactstrap';
import './Clear.css';

function Clear() {
  localStorage.removeItem('okta-token-storage')
  localStorage.removeItem('okta-cache-storage')
  localStorage.clear();
  return (
    <Container className="Clear">
      <div className={`floating-container`}>
        <div className={`floating-content`}>
          <Row>
            <Col xs="12" className={`text-center`}>
              <h2>We're clearing your local storage!</h2>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={`text-center`}>
              <FontAwesomeIcon icon="cog" spin size="6x" />
            </Col>
          </Row>
        </div>
      </div>
      <Redirect to='/environments' />
    </Container>
  );
}

export default Clear;
