import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HtmlIcon from './HtmlIcon.js'
import './EnvironmentOption.css';
import { Icon } from '@iconify/react';


class EnvironmentOption extends Component {

  constructor(props) {
    super(props)

    this.ip = (e) => {
      var cloud = (props.cloud || "AWS")
      if (!(props.disabled || false)) {
        props.ipModal.current.handleShow(props.environment, props.environmentKey, cloud, (props.additionalLinks || []))
      }
    }
  }

  render() {
    let icon;

    if ('undefined' !== typeof this.props.btnFontawesomeIcon) {
      icon = <FontAwesomeIcon icon={this.props.btnFontawesomeIcon} size="2x" />
    } else if ('undefined' !== typeof this.props.btnIconifyIcon) {
      icon = <Icon icon={this.props.btnIconifyIcon} width="65"/>
    } else {
      icon = <HtmlIcon icon={this.props.btnHtmlIcon} />
    }

    var disabledClass = (this.props.disabled || false) ? "disabled" : ""

    return (
      <button className={`EnvironmentOption btn ${this.props.btnType} ${disabledClass}`} onClick={this.ip}>
        <div>
          {icon}
          <span>{this.props.environment}</span>
        </div>
      </button>
    );
  }
}

export default EnvironmentOption;
