import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Col, Container, Row} from 'reactstrap';
import EnvrionmentOption
  from '../../components/environment_button/EnvironmentOption';
import InputModal from '../../components/input_modal/InputModal';
import ConfirmationModal
  from '../../components/confirmation_modal/ConfirmationModal';
import './CockpitChoice.css';
import {withAuth} from "@okta/okta-react";

export default withAuth(
  class CockpitChoice extends React.Component {

    constructor(props) {
      super(props)
      this.ipModalRef = React.createRef();
      this.confirmationModalRef = React.createRef();
    }

    render() {
      return (
        <Container className="CockpitChoice">
          <div className={` text-center`}>
            <FontAwesomeIcon icon="rocket" className={`d-block mx-auto mb-4 header-icon`} />
            <h2 onClick={this.onEnvrionmentChoice}>Voyager Cockpit</h2>
            <p className="lead">It's been a pleasure, but Cockpit is no more!<br/>Please connect to the VPN to access Voyager.</p>
            <p className="lead">Contact Voyager support if you have any issues.</p>
            <p className="lead">Thanks for using this service, long live Cockpit!</p>
          </div>
        </Container>
      );
    }
  }
);