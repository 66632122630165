import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Col, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class InputModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      environment: "None",
      environment_key: "",
      ipAddress: "",
      requesting: false,
      cloud: "",
      additionalLinks: []
    };

    fetch('https://api.ipify.org/')
        .then(res => res.text())
        .then((data) => {
          this.setState({ ipAddress: data })
        })

    this.confirmationModal = props.confirmationModal

    this.toggle = this.toggle.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.requestAccess = this.requestAccess.bind(this);
    this.handleIpChange = this.handleIpChange.bind(this);
    this.requestAccessText = this.requestAccessText.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleShow(env, env_key, cloud_name, additionalLinks) {
    this.setState(prevState => ({
      modal: true,
      environment: env,
      environment_key: env_key,
      cloud: cloud_name,
      additionalLinks: additionalLinks
    }));
  }

  handleIpChange(event) {
    this.setState({ipAddress: event.target.value});
  }

  setAdditionalLinks(additionalLinks) {
    this.setState({additionalLinks: additionalLinks});
  }

  requestAccessText() {
    return this.state.requesting ? <FontAwesomeIcon icon="sync" spin /> : "Let me in!"
  }

  requestAccess(event) {
    this.setState({requesting: true})
    fetch('https://voncn52hk8.execute-api.eu-west-1.amazonaws.com/api/request-access', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "ip": this.state.ipAddress,
        "environment": this.state.environment_key,
        "accessToken": JSON.parse(localStorage.getItem('okta-token-storage') || '')['accessToken']['accessToken'],
        "userName": JSON.parse(localStorage.getItem('okta-token-storage') || '')['idToken']['claims']['name']
      })
    })
    .then(function(response) {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .then(body => {
      console.log("WE HERE1")
      this.confirmationModal.current.handleShow(body['Message'] || "An unknown error occurred..", this.state.additionalLinks)})
    .then(r => this.setState({
      requesting: false,
      modal: false
    }))
    .catch(error => {
      console.log("WE HERE2")
      error.text().then(errorMessage => {
        this.confirmationModal.current.handleShow(
          "An error occurred.. Please refresh the page and try again.\n\nError: "+errorMessage+".\n\nIf this happens many times, please email Voyager Dev.",
          [{name: "Refresh", url: "https://cockpit.voyager-wms.net/", target: "_parent"}]
        )
      })
    })
    .then(r => this.setState({
      requesting: false,
      modal: false
    }));
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Please enter your IP</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="environment" sm={3}>Environment</Label>
                <Col sm={9}>
                  <Input type="text" name="environment" id="environment" value={this.state.environment} readOnly />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="ipAddress" sm={3}>IP Address</Label>
                <Col sm={9}>
                  <Input type="text" name="ipAddress" id="ipAddress" placeholder="1.2.3.4" defaultValue={this.state.ipAddress} onChange={this.handleIpChange} autoFocus={true} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.requestAccess} disabled={this.state.requesting}><span>{this.requestAccessText()}</span></Button>{' '}
            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default InputModal;
