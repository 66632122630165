import React, { Component } from 'react';

class HtmlIcon extends Component {
  render() {
    return (
      <span className={`i`}>{String.fromCharCode(this.props.icon)}</span>
    );
  }
}

export default HtmlIcon;