import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';
import * as serviceWorker from './serviceWorker';

import './bootstrap.darkly.min.css';
import './index.css';

import Home from './pages/Home/Home';
import Clear from './pages/Clear/Clear';
import CockpitChoice from './pages/CockpitChoice/CockpitChoice';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faRocket, faInfo, faWrench, faCog, faWarehouse, faSync, faSpider, faDrumstickBite, faHatCowboySide, faCheese, faBoxOpen, faVial, faChartArea, faToiletPaper, faChartPie, faUmbrellaBeach, faChessRook, faPlaneDeparture, faCheckSquare, faDemocrat, faShapes, faDollyFlatbed, faRobot, faHorse, faTemperatureHigh, faCity, faBeer, faFire} from '@fortawesome/free-solid-svg-icons'
library.add(faRocket, faInfo, faWrench, faCog, faWarehouse, faSync, faSpider, faDrumstickBite, faHatCowboySide, faCheese, faBoxOpen, faVial, faChartArea, faToiletPaper, faChartPie, faUmbrellaBeach, faChessRook, faPlaneDeparture, faCheckSquare, faDemocrat, faShapes, faDollyFlatbed, faRobot, faHorse, faTemperatureHigh, faCity, faBeer, faFire)

const oktaConfig = {
  issuer: 'https://thg.okta.com/oauth2/default',
  redirect_uri: window.location.origin + '/implicit/callback',
  client_id: '0oa8vfi6kif43BGn62p7'
}

ReactDOM.render(
  <Router>
    <Security {...oktaConfig}>
      <Route path="/" exact component={Home} />
      <Route path="/clear" component={Clear} />
      <Route path="/implicit/callback" component={ImplicitCallback} />
      <SecureRoute path="/environments" exact component={CockpitChoice} />
    </Security>
  </Router>,
  document.getElementById('root'),
);

serviceWorker.unregister();
