import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      message: "Hello there",
      additionalLinks: []
    };

    this.toggle = this.toggle.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleShow(message, additionalLinks) {
    console.log(additionalLinks)
    this.setState(prevState => ({
      modal: true,
      message: message,
      additionalLinks: additionalLinks
    }));
  }

  render() {
    let additionalButtons = this.state.additionalLinks.map((data, idx) => {
      return <Button key={idx} color="primary" className="mr-auto" href={data.url} target={data.target || '_blank'}>{data.name}</Button>
    })
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>Notice</ModalHeader>
          <ModalBody>
            <p>{this.state.message}</p>
          </ModalBody>
          <ModalFooter>
            {additionalButtons}
            <Button color="primary" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ConfirmationModal;
